@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&family=Roboto+Serif:wght@400;700&family=Roboto:wght@400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&family=Roboto+Serif:wght@400;700&family=Roboto:wght@400;700&display=swap";
[data-theme="bootstrap/light"] {
  --font-head: "Roboto", sans-serif;
  --font-body: "Roboto", sans-serif;
  --font-quote: "Roboto Serif", serif;
  --font-code: "Roboto Mono", monospace;
  --color-primary: #3880ff;
  --color-primary-subtle: #3880ff33;
  --color-primary-contrast: #fff;
  --color-primary-shade: #3171e0;
  --color-primary-tint: #4c8dff;
  --color-secondary: #3dc2ff;
  --color-secondary-subtle: #3dc2ff33;
  --color-secondary-contrast: #fff;
  --color-secondary-shade: #36abe0;
  --color-secondary-tint: #50c8ff;
  --color-tertiary: #5260ff;
  --color-tertiary-subtle: #5260ff33;
  --color-tertiary-contrast: #fff;
  --color-tertiary-shade: #4854e0;
  --color-tertiary-tint: #6370ff;
  --color-success: #2dd36f;
  --color-success-subtle: #2dd36f33;
  --color-success-contrast: #fff;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;
  --color-warning: #ffc409;
  --color-warning-subtle: #ffc40933;
  --color-warning-contrast: #000;
  --color-warning-shade: #e0ac08;
  --color-warning-tint: #ffca22;
  --color-danger: #eb445a;
  --color-danger-subtle: #eb445a33;
  --color-danger-contrast: #fff;
  --color-danger-shade: #cf3c4f;
  --color-danger-tint: #ed576b;
  --color-dark: #222428;
  --color-dark-subtle: #22242833;
  --color-dark-contrast: #fff;
  --color-dark-shade: #1e2023;
  --color-dark-tint: #383a3e;
  --color-medium: #92949c;
  --color-medium-subtle: #92949c33;
  --color-medium-contrast: #fff;
  --color-medium-shade: #808289;
  --color-medium-tint: #9d9fa6;
  --color-light: #f4f5f8;
  --color-light-subtle: #f4f5f833;
  --color-light-contrast: #000;
  --color-light-shade: #d7d8da;
  --color-light-tint: #fff;
  --color-background: #f4f5f8;
  --color-background-subtle: #f4f5f833;
  --color-background-contrast: #000;
  --color-background-shade: #d7d8da;
  --color-background-tint: #fff;
  --color-middleground: #92949c;
  --color-middleground-subtle: #92949c33;
  --color-middleground-contrast: #fff;
  --color-middleground-shade: #808289;
  --color-middleground-tint: #9d9fa6;
  --color-foreground: #222428;
  --color-foreground-subtle: #22242833;
  --color-foreground-contrast: #fff;
  --color-foreground-shade: #1e2023;
  --color-foreground-tint: #383a3e;
  --gradient-primary: linear-gradient(135deg, #4c8dff 0%, #194ca3 100%);
  --gradient-primary-subtle: linear-gradient(135deg, #4c8dff33 0%, #194ca333 100%);
  --gradient-primary-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-primary-shade: linear-gradient(135deg, #0060cb 0%, #002573 100%);
  --gradient-primary-tint: linear-gradient(135deg, #89bdff 0%, #5977d5 100%);
  --gradient-secondary: linear-gradient(135deg, #50c8ff 0%, #1376a4 100%);
  --gradient-secondary-subtle: linear-gradient(135deg, #50c8ff33 0%, #1376a433 100%);
  --gradient-secondary-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-secondary-shade: linear-gradient(135deg, #0097cc 0%, #004a75 100%);
  --gradient-secondary-tint: linear-gradient(135deg, #8dfbff 0%, #57a5d6 100%);
  --gradient-tertiary: linear-gradient(135deg, #6370ff 0%, #242e9e 100%);
  --gradient-tertiary-subtle: linear-gradient(135deg, #6370ff33 0%, #242e9e33 100%);
  --gradient-tertiary-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-tertiary-shade: linear-gradient(135deg, #1545cb 0%, #00096e 100%);
  --gradient-tertiary-tint: linear-gradient(135deg, #9c9eff 0%, #6058d0 100%);
  --gradient-success: linear-gradient(135deg, #42d77d 0%, #11833f 100%);
  --gradient-success-subtle: linear-gradient(135deg, #42d77d33 0%, #11833f33 100%);
  --gradient-success-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-success-shade: linear-gradient(135deg, #00a44f 0%, #005515 100%);
  --gradient-success-tint: linear-gradient(135deg, #7dffad 0%, #4fb46b 100%);
  --gradient-warning: linear-gradient(135deg, #ffca22 0%, #a68108 100%);
  --gradient-warning-subtle: linear-gradient(135deg, #ffca2233 0%, #a6810833 100%);
  --gradient-warning-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-warning-shade: linear-gradient(135deg, #c79a00 0%, #735500 100%);
  --gradient-warning-tint: linear-gradient(135deg, #fffd5d 0%, #dbb042 100%);
  --gradient-danger: linear-gradient(135deg, #ed576b 0%, #8e1f30 100%);
  --gradient-danger-subtle: linear-gradient(135deg, #ed576b33 0%, #8e1f3033 100%);
  --gradient-danger-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-danger-shade: linear-gradient(135deg, #b51f40 0%, #5b0008 100%);
  --gradient-danger-tint: linear-gradient(135deg, #ff8a99 0%, #c35159 100%);
  --gradient-dark: linear-gradient(135deg, #6b6b6b 0%, #212121 100%);
  --gradient-dark-subtle: linear-gradient(135deg, #6b6b6b33 0%, #21212133 100%);
  --gradient-dark-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-dark-shade: linear-gradient(135deg, #404040 0%, #000 100%);
  --gradient-dark-tint: linear-gradient(135deg, #999 0%, #484848 100%);
  --gradient-medium: linear-gradient(135deg, #c8c9cb 0%, #747781 100%);
  --gradient-medium-subtle: linear-gradient(135deg, #c8c9cb33 0%, #74778133 100%);
  --gradient-medium-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-medium-shade: linear-gradient(135deg, #97989a 0%, #484b55 100%);
  --gradient-medium-tint: linear-gradient(135deg, #fbfcfe 0%, #a3a6b0 100%);
  --gradient-light: linear-gradient(135deg, #fff 0%, #c8c8c8 100%);
  --gradient-light-subtle: linear-gradient(135deg, #fff3 0%, #c8c8c833 100%);
  --gradient-light-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-light-shade: linear-gradient(135deg, #ccc 0%, #979797 100%);
  --gradient-light-tint: linear-gradient(135deg, #fff 0%, #fbfbfb 100%);
  --gradient-background: linear-gradient(135deg, #fff 0%, #c8c8c8 100%);
  --gradient-background-subtle: linear-gradient(135deg, #fff3 0%, #c8c8c833 100%);
  --gradient-background-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-background-shade: linear-gradient(135deg, #ccc 0%, #979797 100%);
  --gradient-background-tint: linear-gradient(135deg, #fff 0%, #fbfbfb 100%);
  --gradient-middleground: linear-gradient(135deg, #c8c9cb 0%, #747781 100%);
  --gradient-middleground-subtle: linear-gradient(135deg, #c8c9cb33 0%, #74778133 100%);
  --gradient-middleground-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-middleground-shade: linear-gradient(135deg, #97989a 0%, #484b55 100%);
  --gradient-middleground-tint: linear-gradient(135deg, #fbfcfe 0%, #a3a6b0 100%);
  --gradient-foreground: linear-gradient(135deg, #6b6b6b 0%, #212121 100%);
  --gradient-foreground-subtle: linear-gradient(135deg, #6b6b6b33 0%, #21212133 100%);
  --gradient-foreground-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-foreground-shade: linear-gradient(135deg, #404040 0%, #000 100%);
  --gradient-foreground-tint: linear-gradient(135deg, #999 0%, #484848 100%);
  --size-text: 16px;
  --size-space: 1rem;
  --size-outline: 1px;
  --size-border: 1px;
  --size-radius: 5px;
  --scale-xxxs: 3px;
  --scale-xxs: 5px;
  --scale-xs: 7px;
  --scale-ss: 9px;
  --scale-sm: 12px;
  --scale-md: 15px;
  --scale-ml: 18px;
  --scale-lg: 21px;
  --scale-sl: 24px;
  --scale-xl: 27px;
  --scale-xxl: 30px;
  --scale-xxxl: 34px;
  --wide-xxxs: 150px;
  --wide-xxs: 240px;
  --wide-xs: 320px;
  --wide-ss: 425px;
  --wide-sm: 576px;
  --wide-md: 768px;
  --wide-ml: 992px;
  --wide-lg: 1024px;
  --wide-sl: 1200px;
  --wide-xl: 1440px;
  --wide-xxl: 2560px;
  --wide-xxxl: 3840px;
  --shadow-normal: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  --shadow-least: 0 1px 2px 0 #0000000d;
  --shadow-lesser: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  --shadow-greater: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  --shadow-greatest: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  --shadow-scarcity: 0 0 0 1px #0000000d;
  --shadow-excess: 0px 0px 0px 1px #0000001a, 0px 5px 10px #0003, 0px 15px 40px #0006;
}

[data-theme="bootstrap/dark"] {
  --font-head: "Roboto", sans-serif;
  --font-body: "Roboto", sans-serif;
  --font-quote: "Roboto Serif", serif;
  --font-code: "Roboto Mono", monospace;
  --color-primary: #3880ff;
  --color-primary-subtle: #3880ff33;
  --color-primary-contrast: #fff;
  --color-primary-shade: #3171e0;
  --color-primary-tint: #4c8dff;
  --color-secondary: #3dc2ff;
  --color-secondary-subtle: #3dc2ff33;
  --color-secondary-contrast: #fff;
  --color-secondary-shade: #36abe0;
  --color-secondary-tint: #50c8ff;
  --color-tertiary: #5260ff;
  --color-tertiary-subtle: #5260ff33;
  --color-tertiary-contrast: #fff;
  --color-tertiary-shade: #4854e0;
  --color-tertiary-tint: #6370ff;
  --color-success: #2dd36f;
  --color-success-subtle: #2dd36f33;
  --color-success-contrast: #fff;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;
  --color-warning: #ffc409;
  --color-warning-subtle: #ffc40933;
  --color-warning-contrast: #000;
  --color-warning-shade: #e0ac08;
  --color-warning-tint: #ffca22;
  --color-danger: #eb445a;
  --color-danger-subtle: #eb445a33;
  --color-danger-contrast: #fff;
  --color-danger-shade: #cf3c4f;
  --color-danger-tint: #ed576b;
  --color-dark: #222428;
  --color-dark-subtle: #22242833;
  --color-dark-contrast: #fff;
  --color-dark-shade: #1e2023;
  --color-dark-tint: #383a3e;
  --color-medium: #92949c;
  --color-medium-subtle: #92949c33;
  --color-medium-contrast: #fff;
  --color-medium-shade: #808289;
  --color-medium-tint: #9d9fa6;
  --color-light: #f4f5f8;
  --color-light-subtle: #f4f5f833;
  --color-light-contrast: #000;
  --color-light-shade: #d7d8da;
  --color-light-tint: #fff;
  --color-background: #222428;
  --color-background-subtle: #22242833;
  --color-background-contrast: #fff;
  --color-background-shade: #1e2023;
  --color-background-tint: #383a3e;
  --color-middleground: #92949c;
  --color-middleground-subtle: #92949c33;
  --color-middleground-contrast: #fff;
  --color-middleground-shade: #808289;
  --color-middleground-tint: #9d9fa6;
  --color-foreground: #f4f5f8;
  --color-foreground-subtle: #f4f5f833;
  --color-foreground-contrast: #000;
  --color-foreground-shade: #d7d8da;
  --color-foreground-tint: #fff;
  --gradient-primary: linear-gradient(135deg, #4c8dff 0%, #194ca3 100%);
  --gradient-primary-subtle: linear-gradient(135deg, #4c8dff33 0%, #194ca333 100%);
  --gradient-primary-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-primary-shade: linear-gradient(135deg, #0060cb 0%, #002573 100%);
  --gradient-primary-tint: linear-gradient(135deg, #89bdff 0%, #5977d5 100%);
  --gradient-secondary: linear-gradient(135deg, #50c8ff 0%, #1376a4 100%);
  --gradient-secondary-subtle: linear-gradient(135deg, #50c8ff33 0%, #1376a433 100%);
  --gradient-secondary-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-secondary-shade: linear-gradient(135deg, #0097cc 0%, #004a75 100%);
  --gradient-secondary-tint: linear-gradient(135deg, #8dfbff 0%, #57a5d6 100%);
  --gradient-tertiary: linear-gradient(135deg, #6370ff 0%, #242e9e 100%);
  --gradient-tertiary-subtle: linear-gradient(135deg, #6370ff33 0%, #242e9e33 100%);
  --gradient-tertiary-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-tertiary-shade: linear-gradient(135deg, #1545cb 0%, #00096e 100%);
  --gradient-tertiary-tint: linear-gradient(135deg, #9c9eff 0%, #6058d0 100%);
  --gradient-success: linear-gradient(135deg, #42d77d 0%, #11833f 100%);
  --gradient-success-subtle: linear-gradient(135deg, #42d77d33 0%, #11833f33 100%);
  --gradient-success-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-success-shade: linear-gradient(135deg, #00a44f 0%, #005515 100%);
  --gradient-success-tint: linear-gradient(135deg, #7dffad 0%, #4fb46b 100%);
  --gradient-warning: linear-gradient(135deg, #ffca22 0%, #a68108 100%);
  --gradient-warning-subtle: linear-gradient(135deg, #ffca2233 0%, #a6810833 100%);
  --gradient-warning-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-warning-shade: linear-gradient(135deg, #c79a00 0%, #735500 100%);
  --gradient-warning-tint: linear-gradient(135deg, #fffd5d 0%, #dbb042 100%);
  --gradient-danger: linear-gradient(135deg, #ed576b 0%, #8e1f30 100%);
  --gradient-danger-subtle: linear-gradient(135deg, #ed576b33 0%, #8e1f3033 100%);
  --gradient-danger-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-danger-shade: linear-gradient(135deg, #b51f40 0%, #5b0008 100%);
  --gradient-danger-tint: linear-gradient(135deg, #ff8a99 0%, #c35159 100%);
  --gradient-dark: linear-gradient(135deg, #6b6b6b 0%, #212121 100%);
  --gradient-dark-subtle: linear-gradient(135deg, #6b6b6b33 0%, #21212133 100%);
  --gradient-dark-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-dark-shade: linear-gradient(135deg, #404040 0%, #000 100%);
  --gradient-dark-tint: linear-gradient(135deg, #999 0%, #484848 100%);
  --gradient-medium: linear-gradient(135deg, #c8c9cb 0%, #747781 100%);
  --gradient-medium-subtle: linear-gradient(135deg, #c8c9cb33 0%, #74778133 100%);
  --gradient-medium-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-medium-shade: linear-gradient(135deg, #97989a 0%, #484b55 100%);
  --gradient-medium-tint: linear-gradient(135deg, #fbfcfe 0%, #a3a6b0 100%);
  --gradient-light: linear-gradient(135deg, #fff 0%, #c8c8c8 100%);
  --gradient-light-subtle: linear-gradient(135deg, #fff3 0%, #c8c8c833 100%);
  --gradient-light-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-light-shade: linear-gradient(135deg, #ccc 0%, #979797 100%);
  --gradient-light-tint: linear-gradient(135deg, #fff 0%, #fbfbfb 100%);
  --gradient-background: linear-gradient(135deg, #6b6b6b 0%, #212121 100%);
  --gradient-background-subtle: linear-gradient(135deg, #6b6b6b33 0%, #21212133 100%);
  --gradient-background-contrast: linear-gradient(135deg, #fff 0%, #9b9b9b 100%);
  --gradient-background-shade: linear-gradient(135deg, #404040 0%, #000 100%);
  --gradient-background-tint: linear-gradient(135deg, #999 0%, #484848 100%);
  --gradient-middleground: linear-gradient(135deg, #c8c9cb 0%, #747781 100%);
  --gradient-middleground-subtle: linear-gradient(135deg, #c8c9cb33 0%, #74778133 100%);
  --gradient-middleground-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-middleground-shade: linear-gradient(135deg, #97989a 0%, #484b55 100%);
  --gradient-middleground-tint: linear-gradient(135deg, #fbfcfe 0%, #a3a6b0 100%);
  --gradient-foreground: linear-gradient(135deg, #fff 0%, #c8c8c8 100%);
  --gradient-foreground-subtle: linear-gradient(135deg, #fff3 0%, #c8c8c833 100%);
  --gradient-foreground-contrast: linear-gradient(135deg, #000 0%, #555 100%);
  --gradient-foreground-shade: linear-gradient(135deg, #ccc 0%, #979797 100%);
  --gradient-foreground-tint: linear-gradient(135deg, #fff 0%, #fbfbfb 100%);
  --size-text: 16px;
  --size-space: 1rem;
  --size-outline: 1px;
  --size-border: 1px;
  --size-radius: 5px;
  --scale-xxxs: 3px;
  --scale-xxs: 5px;
  --scale-xs: 7px;
  --scale-ss: 9px;
  --scale-sm: 12px;
  --scale-md: 15px;
  --scale-ml: 18px;
  --scale-lg: 21px;
  --scale-sl: 24px;
  --scale-xl: 27px;
  --scale-xxl: 30px;
  --scale-xxxl: 34px;
  --wide-xxxs: 150px;
  --wide-xxs: 240px;
  --wide-xs: 320px;
  --wide-ss: 425px;
  --wide-sm: 576px;
  --wide-md: 768px;
  --wide-ml: 992px;
  --wide-lg: 1024px;
  --wide-sl: 1200px;
  --wide-xl: 1440px;
  --wide-xxl: 2560px;
  --wide-xxxl: 3840px;
  --shadow-normal: 0 4px 6px -1px #0003, 0 2px 4px -1px #00000029;
  --shadow-least: 0 1px 2px 0 #00000026;
  --shadow-lesser: 0 1px 3px 0 #0003, 0 1px 2px 0 #00000029;
  --shadow-greater: 0 10px 15px -3px #0003, 0 4px 6px -2px #00000026;
  --shadow-greatest: 0 20px 25px -5px #0003, 0 10px 10px -5px #00000024;
  --shadow-scarcity: 0 0 0 1px #00000026;
  --shadow-excess: 0px 0px 0px 1px #0003, 0px 5px 10px #0000004d, 0px 15px 40px #00000080;
}

[data-theme] {
  --color-gray: #b5bed9;
  --color-gray-subtle: #b5bed933;
  --color-gray-contrast: #000;
  --color-gray-shade: #a3aed0;
  --color-gray-tint: #c9d0e3;
  --color-zinc: #71717a;
  --color-zinc-subtle: #71717a33;
  --color-zinc-contrast: #fff;
  --color-zinc-shade: #52525b;
  --color-zinc-tint: #a1a1aa;
  --color-brown: #795c34;
  --color-brown-subtle: #795c3433;
  --color-brown-contrast: #fff;
  --color-brown-shade: #4b371c;
  --color-brown-tint: #9a7b4f;
  --color-amber: #fbbf24;
  --color-amber-subtle: #fbbf2433;
  --color-amber-contrast: #000;
  --color-amber-shade: #f59e0b;
  --color-amber-tint: #fcd34d;
  --color-yellow: #fbcf33;
  --color-yellow-subtle: #fbcf3333;
  --color-yellow-contrast: #000;
  --color-yellow-shade: #eab308;
  --color-yellow-tint: #fde047;
  --color-orange: #fb923c;
  --color-orange-subtle: #fb923c33;
  --color-orange-contrast: #fff;
  --color-orange-shade: #f97316;
  --color-orange-tint: #fdba74;
  --color-lime: #98ec2d;
  --color-lime-subtle: #98ec2d33;
  --color-lime-contrast: #000;
  --color-lime-shade: #82d616;
  --color-lime-tint: #bef264;
  --color-green: #4ade80;
  --color-green-subtle: #4ade8033;
  --color-green-contrast: #fff;
  --color-green-shade: #22c55e;
  --color-green-tint: #86efac;
  --color-teal: #2dd4bf;
  --color-teal-subtle: #2dd4bf33;
  --color-teal-contrast: #fff;
  --color-teal-shade: #14b8a6;
  --color-teal-tint: #5eead4;
  --color-cyan: #21d4fd;
  --color-cyan-subtle: #21d4fd33;
  --color-cyan-contrast: #000;
  --color-cyan-shade: #17c1e8;
  --color-cyan-tint: #67e8f9;
  --color-blue: #42a5f5;
  --color-blue-subtle: #42a5f533;
  --color-blue-contrast: #fff;
  --color-blue-shade: #2196f3;
  --color-blue-tint: #64b5f6;
  --color-navy: #1b3bbb;
  --color-navy-subtle: #1b3bbb33;
  --color-navy-contrast: #fff;
  --color-navy-shade: #24388a;
  --color-navy-tint: #3652ba;
  --color-indigo: #818cf8;
  --color-indigo-subtle: #818cf833;
  --color-indigo-contrast: #fff;
  --color-indigo-shade: #6366f1;
  --color-indigo-tint: #a5b4fc;
  --color-violet: #7f00ff;
  --color-violet-subtle: #7f00ff33;
  --color-violet-contrast: #fff;
  --color-violet-shade: #710193;
  --color-violet-tint: #8f00ff;
  --color-purple: #c084fc;
  --color-purple-subtle: #c084fc33;
  --color-purple-contrast: #fff;
  --color-purple-shade: #a855f7;
  --color-purple-tint: #d8b4fe;
  --color-pink: #f472b6;
  --color-pink-subtle: #f472b633;
  --color-pink-contrast: #fff;
  --color-pink-shade: #ff0080;
  --color-pink-tint: #f9a8d4;
  --color-red: #f87171;
  --color-red-subtle: #f8717133;
  --color-red-contrast: #fff;
  --color-red-shade: #f53939;
  --color-red-tint: #fca5a5;
  --gradient-vital-ocean: linear-gradient(90deg, #1cb5e0 0%, #000851 100%);
  --gradient-vital-ocean-subtle: linear-gradient(90deg, #1cb5e033 0%, #00085133 100%);
  --gradient-vital-ocean-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-vital-ocean-shade: linear-gradient(90deg, #0d93b8 0%, #00031e 100%);
  --gradient-vital-ocean-tint: linear-gradient(90deg, #31cdf8 0%, #051181 100%);
  --gradient-kale-salad: linear-gradient(90deg, #00c9ff 0%, #92fe9d 100%);
  --gradient-kale-salad-subtle: linear-gradient(90deg, #00c9ff33 0%, #92fe9d33 100%);
  --gradient-kale-salad-contrast: linear-gradient(60deg, #29323c 0%, #485563 100%);
  --gradient-kale-salad-shade: linear-gradient(90deg, #0098c2 0%, #68c972 100%);
  --gradient-kale-salad-tint: linear-gradient(90deg, #14c6f7 0%, #a5faae 100%);
  --gradient-disco-club: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
  --gradient-disco-club-subtle: linear-gradient(90deg, #fc466b33 0%, #3f5efb33 100%);
  --gradient-disco-club-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-disco-club-shade: linear-gradient(90deg, #d43151 0%, #2a46d2 100%);
  --gradient-disco-club-tint: linear-gradient(90deg, #fb5a7a 0%, #5570f8 100%);
  --gradient-shady-lane: linear-gradient(90deg, #3f2b96 0%, #a8c0ff 100%);
  --gradient-shady-lane-subtle: linear-gradient(90deg, #3f2b9633 0%, #a8c0ff33 100%);
  --gradient-shady-lane-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-shady-lane-shade: linear-gradient(90deg, #291a6c 0%, #7f97d4 100%);
  --gradient-shady-lane-tint: linear-gradient(90deg, #5741bb 0%, #bbcdfb 100%);
  --gradient-retro-wagon: linear-gradient(90deg, #fdbb2d 0%, #22c1c3 100%);
  --gradient-retro-wagon-subtle: linear-gradient(90deg, #fdbb2d33 0%, #22c1c333 100%);
  --gradient-retro-wagon-contrast: linear-gradient(60deg, #29323c 0%, #485563 100%);
  --gradient-retro-wagon-shade: linear-gradient(90deg, #d39819 0%, #119194 100%);
  --gradient-retro-wagon-tint: linear-gradient(90deg, #fac044 0%, #3be3e6 100%);
  --gradient-fresco-crush: linear-gradient(90deg, #fdbb2d 0%, #3a1c71 100%);
  --gradient-fresco-crush-subtle: linear-gradient(90deg, #fdbb2d33 0%, #3a1c7133 100%);
  --gradient-fresco-crush-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-fresco-crush-shade: linear-gradient(90deg, #ce9416 0%, #210d47 100%);
  --gradient-fresco-crush-tint: linear-gradient(90deg, #fdc345 0%, #533094 100%);
  --gradient-cucumber-water: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  --gradient-cucumber-water-subtle: linear-gradient(90deg, #e3ffe733 0%, #d9e7ff33 100%);
  --gradient-cucumber-water-contrast: linear-gradient(60deg, #29323c 0%, #485563 100%);
  --gradient-cucumber-water-shade: linear-gradient(90deg, #b0d7b6 0%, #a3b5d2 100%);
  --gradient-cucumber-water-tint: linear-gradient(90deg, #f2fbf3 0%, #eaf0fb 100%);
  --gradient-sea-salt: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  --gradient-sea-salt-subtle: linear-gradient(90deg, #4b6cb733 0%, #18284833 100%);
  --gradient-sea-salt-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-sea-salt-shade: linear-gradient(90deg, #2f4b8f 0%, #091224 100%);
  --gradient-sea-salt-tint: linear-gradient(90deg, #698ad6 0%, #304672 100%);
  --gradient-par-four: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
  --gradient-par-four-subtle: linear-gradient(90deg, #9ebd1333 0%, #00855233 100%);
  --gradient-par-four-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-par-four-shade: linear-gradient(90deg, #789205 0%, #00472c 100%);
  --gradient-par-four-tint: linear-gradient(90deg, #bbdc28 0%, #0bc57d 100%);
  --gradient-ooey-gooey: linear-gradient(90deg, #0700b8 0%, #0f8 100%);
  --gradient-ooey-gooey-subtle: linear-gradient(90deg, #0700b833 0%, #0f83 100%);
  --gradient-ooey-gooey-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-ooey-gooey-shade: linear-gradient(90deg, #04006e 0%, #00af5e 100%);
  --gradient-ooey-gooey-tint: linear-gradient(90deg, #140dec 0%, #17fa90 100%);
  --gradient-bloody-mimosa: linear-gradient(90deg, #d53369 0%, #daae51 100%);
  --gradient-bloody-mimosa-subtle: linear-gradient(90deg, #d5336933 0%, #daae5133 100%);
  --gradient-bloody-mimosa-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-bloody-mimosa-shade: linear-gradient(90deg, #a41b49 0%, #ad8631 100%);
  --gradient-bloody-mimosa-tint: linear-gradient(90deg, #f85189 0%, #facf72 100%);
  --gradient-lovely-lilly: linear-gradient(90deg, #efd5ff 0%, #515ada 100%);
  --gradient-lovely-lilly-subtle: linear-gradient(90deg, #efd5ff33 0%, #515ada33 100%);
  --gradient-lovely-lilly-contrast: linear-gradient(60deg, #29323c 0%, #485563 100%);
  --gradient-lovely-lilly-shade: linear-gradient(90deg, #c0a1d3 0%, #343cb2 100%);
  --gradient-lovely-lilly-tint: linear-gradient(90deg, #f6e8fe 0%, #727af6 100%);
  --gradient-aqua-spray: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
  --gradient-aqua-spray-subtle: linear-gradient(90deg, #00d2ff33 0%, #3a47d533 100%);
  --gradient-aqua-spray-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-aqua-spray-shade: linear-gradient(90deg, #0091b1 0%, #202ba7 100%);
  --gradient-aqua-spray-tint: linear-gradient(90deg, #1ad2fb 0%, #5e6bf9 100%);
  --gradient-mello-yellow: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%);
  --gradient-mello-yellow-subtle: linear-gradient(90deg, #f8ff0033 0%, #3ad59f33 100%);
  --gradient-mello-yellow-contrast: linear-gradient(60deg, #29323c 0%, #485563 100%);
  --gradient-mello-yellow-shade: linear-gradient(90deg, #a5aa01 0%, #1fa173 100%);
  --gradient-mello-yellow-tint: linear-gradient(90deg, #f7ff1c 0%, #5ffac4 100%);
  --gradient-dusty-cactus: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);
  --gradient-dusty-cactus-subtle: linear-gradient(90deg, #fcff9e33 0%, #c6770033 100%);
  --gradient-dusty-cactus-contrast: linear-gradient(60deg, #29323c 0%, #485563 100%);
  --gradient-dusty-cactus-shade: linear-gradient(90deg, #cbce6d 0%, #774700 100%);
  --gradient-dusty-cactus-tint: linear-gradient(90deg, #fdffb8 0%, #fea115 100%);
  --gradient-premium-dark: linear-gradient(90deg, #434343 0%, #000 100%);
  --gradient-premium-dark-subtle: linear-gradient(90deg, #43434333 0%, #0003 100%);
  --gradient-premium-dark-contrast: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  --gradient-premium-dark-shade: linear-gradient(90deg, #272424 0%, #000 100%);
  --gradient-premium-dark-tint: linear-gradient(90deg, #767676 0%, #272424 100%);
  --gradient-perfect-white: linear-gradient(-225deg, #e3fdf5 0%, #ffe6fa 100%);
  --gradient-perfect-white-subtle: linear-gradient(-225deg, #e3fdf533 0%, #ffe6fa33 100%);
  --gradient-perfect-white-contrast: linear-gradient(60deg, #29323c 0%, #485563 100%);
  --gradient-perfect-white-shade: linear-gradient(-225deg, #afd0c6 0%, #d0b2ca 100%);
  --gradient-perfect-white-tint: linear-gradient(-225deg, #f5fcfa 0%, #fbf5fa 100%);
  --scale-xxxs-0x: calc(var(--scale-xxxs) * 0);
  --scale-xxxs-0_1x: calc(var(--scale-xxxs) * .1);
  --scale-xxxs-0_2x: calc(var(--scale-xxxs) * .2);
  --scale-xxxs-0_25x: calc(var(--scale-xxxs) * .25);
  --scale-xxxs-0_3x: calc(var(--scale-xxxs) * .3);
  --scale-xxxs-0_4x: calc(var(--scale-xxxs) * .4);
  --scale-xxxs-0_5x: calc(var(--scale-xxxs) * .5);
  --scale-xxxs-0_6x: calc(var(--scale-xxxs) * .6);
  --scale-xxxs-0_7x: calc(var(--scale-xxxs) * .7);
  --scale-xxxs-0_75x: calc(var(--scale-xxxs) * .75);
  --scale-xxxs-0_8x: calc(var(--scale-xxxs) * .8);
  --scale-xxxs-0_9x: calc(var(--scale-xxxs) * .9);
  --scale-xxxs-1x: calc(var(--scale-xxxs) * 1);
  --scale-xxxs-1_25x: calc(var(--scale-xxxs) * 1.25);
  --scale-xxxs-1_5x: calc(var(--scale-xxxs) * 1.5);
  --scale-xxxs-1_75x: calc(var(--scale-xxxs) * 1.75);
  --scale-xxxs-2x: calc(var(--scale-xxxs) * 2);
  --scale-xxxs-3x: calc(var(--scale-xxxs) * 3);
  --scale-xxxs-4x: calc(var(--scale-xxxs) * 4);
  --scale-xxxs-5x: calc(var(--scale-xxxs) * 5);
  --scale-xxxs-6x: calc(var(--scale-xxxs) * 6);
  --scale-xxxs-7x: calc(var(--scale-xxxs) * 7);
  --scale-xxxs-8x: calc(var(--scale-xxxs) * 8);
  --scale-xxxs-9x: calc(var(--scale-xxxs) * 9);
  --scale-xxxs-10x: calc(var(--scale-xxxs) * 10);
  --scale-xxs-0x: calc(var(--scale-xxs) * 0);
  --scale-xxs-0_1x: calc(var(--scale-xxs) * .1);
  --scale-xxs-0_2x: calc(var(--scale-xxs) * .2);
  --scale-xxs-0_25x: calc(var(--scale-xxs) * .25);
  --scale-xxs-0_3x: calc(var(--scale-xxs) * .3);
  --scale-xxs-0_4x: calc(var(--scale-xxs) * .4);
  --scale-xxs-0_5x: calc(var(--scale-xxs) * .5);
  --scale-xxs-0_6x: calc(var(--scale-xxs) * .6);
  --scale-xxs-0_7x: calc(var(--scale-xxs) * .7);
  --scale-xxs-0_75x: calc(var(--scale-xxs) * .75);
  --scale-xxs-0_8x: calc(var(--scale-xxs) * .8);
  --scale-xxs-0_9x: calc(var(--scale-xxs) * .9);
  --scale-xxs-1x: calc(var(--scale-xxs) * 1);
  --scale-xxs-1_25x: calc(var(--scale-xxs) * 1.25);
  --scale-xxs-1_5x: calc(var(--scale-xxs) * 1.5);
  --scale-xxs-1_75x: calc(var(--scale-xxs) * 1.75);
  --scale-xxs-2x: calc(var(--scale-xxs) * 2);
  --scale-xxs-3x: calc(var(--scale-xxs) * 3);
  --scale-xxs-4x: calc(var(--scale-xxs) * 4);
  --scale-xxs-5x: calc(var(--scale-xxs) * 5);
  --scale-xxs-6x: calc(var(--scale-xxs) * 6);
  --scale-xxs-7x: calc(var(--scale-xxs) * 7);
  --scale-xxs-8x: calc(var(--scale-xxs) * 8);
  --scale-xxs-9x: calc(var(--scale-xxs) * 9);
  --scale-xxs-10x: calc(var(--scale-xxs) * 10);
  --scale-xs-0x: calc(var(--scale-xs) * 0);
  --scale-xs-0_1x: calc(var(--scale-xs) * .1);
  --scale-xs-0_2x: calc(var(--scale-xs) * .2);
  --scale-xs-0_25x: calc(var(--scale-xs) * .25);
  --scale-xs-0_3x: calc(var(--scale-xs) * .3);
  --scale-xs-0_4x: calc(var(--scale-xs) * .4);
  --scale-xs-0_5x: calc(var(--scale-xs) * .5);
  --scale-xs-0_6x: calc(var(--scale-xs) * .6);
  --scale-xs-0_7x: calc(var(--scale-xs) * .7);
  --scale-xs-0_75x: calc(var(--scale-xs) * .75);
  --scale-xs-0_8x: calc(var(--scale-xs) * .8);
  --scale-xs-0_9x: calc(var(--scale-xs) * .9);
  --scale-xs-1x: calc(var(--scale-xs) * 1);
  --scale-xs-1_25x: calc(var(--scale-xs) * 1.25);
  --scale-xs-1_5x: calc(var(--scale-xs) * 1.5);
  --scale-xs-1_75x: calc(var(--scale-xs) * 1.75);
  --scale-xs-2x: calc(var(--scale-xs) * 2);
  --scale-xs-3x: calc(var(--scale-xs) * 3);
  --scale-xs-4x: calc(var(--scale-xs) * 4);
  --scale-xs-5x: calc(var(--scale-xs) * 5);
  --scale-xs-6x: calc(var(--scale-xs) * 6);
  --scale-xs-7x: calc(var(--scale-xs) * 7);
  --scale-xs-8x: calc(var(--scale-xs) * 8);
  --scale-xs-9x: calc(var(--scale-xs) * 9);
  --scale-xs-10x: calc(var(--scale-xs) * 10);
  --scale-ss-0x: calc(var(--scale-ss) * 0);
  --scale-ss-0_1x: calc(var(--scale-ss) * .1);
  --scale-ss-0_2x: calc(var(--scale-ss) * .2);
  --scale-ss-0_25x: calc(var(--scale-ss) * .25);
  --scale-ss-0_3x: calc(var(--scale-ss) * .3);
  --scale-ss-0_4x: calc(var(--scale-ss) * .4);
  --scale-ss-0_5x: calc(var(--scale-ss) * .5);
  --scale-ss-0_6x: calc(var(--scale-ss) * .6);
  --scale-ss-0_7x: calc(var(--scale-ss) * .7);
  --scale-ss-0_75x: calc(var(--scale-ss) * .75);
  --scale-ss-0_8x: calc(var(--scale-ss) * .8);
  --scale-ss-0_9x: calc(var(--scale-ss) * .9);
  --scale-ss-1x: calc(var(--scale-ss) * 1);
  --scale-ss-1_25x: calc(var(--scale-ss) * 1.25);
  --scale-ss-1_5x: calc(var(--scale-ss) * 1.5);
  --scale-ss-1_75x: calc(var(--scale-ss) * 1.75);
  --scale-ss-2x: calc(var(--scale-ss) * 2);
  --scale-ss-3x: calc(var(--scale-ss) * 3);
  --scale-ss-4x: calc(var(--scale-ss) * 4);
  --scale-ss-5x: calc(var(--scale-ss) * 5);
  --scale-ss-6x: calc(var(--scale-ss) * 6);
  --scale-ss-7x: calc(var(--scale-ss) * 7);
  --scale-ss-8x: calc(var(--scale-ss) * 8);
  --scale-ss-9x: calc(var(--scale-ss) * 9);
  --scale-ss-10x: calc(var(--scale-ss) * 10);
  --scale-sm-0x: calc(var(--scale-sm) * 0);
  --scale-sm-0_1x: calc(var(--scale-sm) * .1);
  --scale-sm-0_2x: calc(var(--scale-sm) * .2);
  --scale-sm-0_25x: calc(var(--scale-sm) * .25);
  --scale-sm-0_3x: calc(var(--scale-sm) * .3);
  --scale-sm-0_4x: calc(var(--scale-sm) * .4);
  --scale-sm-0_5x: calc(var(--scale-sm) * .5);
  --scale-sm-0_6x: calc(var(--scale-sm) * .6);
  --scale-sm-0_7x: calc(var(--scale-sm) * .7);
  --scale-sm-0_75x: calc(var(--scale-sm) * .75);
  --scale-sm-0_8x: calc(var(--scale-sm) * .8);
  --scale-sm-0_9x: calc(var(--scale-sm) * .9);
  --scale-sm-1x: calc(var(--scale-sm) * 1);
  --scale-sm-1_25x: calc(var(--scale-sm) * 1.25);
  --scale-sm-1_5x: calc(var(--scale-sm) * 1.5);
  --scale-sm-1_75x: calc(var(--scale-sm) * 1.75);
  --scale-sm-2x: calc(var(--scale-sm) * 2);
  --scale-sm-3x: calc(var(--scale-sm) * 3);
  --scale-sm-4x: calc(var(--scale-sm) * 4);
  --scale-sm-5x: calc(var(--scale-sm) * 5);
  --scale-sm-6x: calc(var(--scale-sm) * 6);
  --scale-sm-7x: calc(var(--scale-sm) * 7);
  --scale-sm-8x: calc(var(--scale-sm) * 8);
  --scale-sm-9x: calc(var(--scale-sm) * 9);
  --scale-sm-10x: calc(var(--scale-sm) * 10);
  --scale-md-0x: calc(var(--scale-md) * 0);
  --scale-md-0_1x: calc(var(--scale-md) * .1);
  --scale-md-0_2x: calc(var(--scale-md) * .2);
  --scale-md-0_25x: calc(var(--scale-md) * .25);
  --scale-md-0_3x: calc(var(--scale-md) * .3);
  --scale-md-0_4x: calc(var(--scale-md) * .4);
  --scale-md-0_5x: calc(var(--scale-md) * .5);
  --scale-md-0_6x: calc(var(--scale-md) * .6);
  --scale-md-0_7x: calc(var(--scale-md) * .7);
  --scale-md-0_75x: calc(var(--scale-md) * .75);
  --scale-md-0_8x: calc(var(--scale-md) * .8);
  --scale-md-0_9x: calc(var(--scale-md) * .9);
  --scale-md-1x: calc(var(--scale-md) * 1);
  --scale-md-1_25x: calc(var(--scale-md) * 1.25);
  --scale-md-1_5x: calc(var(--scale-md) * 1.5);
  --scale-md-1_75x: calc(var(--scale-md) * 1.75);
  --scale-md-2x: calc(var(--scale-md) * 2);
  --scale-md-3x: calc(var(--scale-md) * 3);
  --scale-md-4x: calc(var(--scale-md) * 4);
  --scale-md-5x: calc(var(--scale-md) * 5);
  --scale-md-6x: calc(var(--scale-md) * 6);
  --scale-md-7x: calc(var(--scale-md) * 7);
  --scale-md-8x: calc(var(--scale-md) * 8);
  --scale-md-9x: calc(var(--scale-md) * 9);
  --scale-md-10x: calc(var(--scale-md) * 10);
  --scale-ml-0x: calc(var(--scale-ml) * 0);
  --scale-ml-0_1x: calc(var(--scale-ml) * .1);
  --scale-ml-0_2x: calc(var(--scale-ml) * .2);
  --scale-ml-0_25x: calc(var(--scale-ml) * .25);
  --scale-ml-0_3x: calc(var(--scale-ml) * .3);
  --scale-ml-0_4x: calc(var(--scale-ml) * .4);
  --scale-ml-0_5x: calc(var(--scale-ml) * .5);
  --scale-ml-0_6x: calc(var(--scale-ml) * .6);
  --scale-ml-0_7x: calc(var(--scale-ml) * .7);
  --scale-ml-0_75x: calc(var(--scale-ml) * .75);
  --scale-ml-0_8x: calc(var(--scale-ml) * .8);
  --scale-ml-0_9x: calc(var(--scale-ml) * .9);
  --scale-ml-1x: calc(var(--scale-ml) * 1);
  --scale-ml-1_25x: calc(var(--scale-ml) * 1.25);
  --scale-ml-1_5x: calc(var(--scale-ml) * 1.5);
  --scale-ml-1_75x: calc(var(--scale-ml) * 1.75);
  --scale-ml-2x: calc(var(--scale-ml) * 2);
  --scale-ml-3x: calc(var(--scale-ml) * 3);
  --scale-ml-4x: calc(var(--scale-ml) * 4);
  --scale-ml-5x: calc(var(--scale-ml) * 5);
  --scale-ml-6x: calc(var(--scale-ml) * 6);
  --scale-ml-7x: calc(var(--scale-ml) * 7);
  --scale-ml-8x: calc(var(--scale-ml) * 8);
  --scale-ml-9x: calc(var(--scale-ml) * 9);
  --scale-ml-10x: calc(var(--scale-ml) * 10);
  --scale-lg-0x: calc(var(--scale-lg) * 0);
  --scale-lg-0_1x: calc(var(--scale-lg) * .1);
  --scale-lg-0_2x: calc(var(--scale-lg) * .2);
  --scale-lg-0_25x: calc(var(--scale-lg) * .25);
  --scale-lg-0_3x: calc(var(--scale-lg) * .3);
  --scale-lg-0_4x: calc(var(--scale-lg) * .4);
  --scale-lg-0_5x: calc(var(--scale-lg) * .5);
  --scale-lg-0_6x: calc(var(--scale-lg) * .6);
  --scale-lg-0_7x: calc(var(--scale-lg) * .7);
  --scale-lg-0_75x: calc(var(--scale-lg) * .75);
  --scale-lg-0_8x: calc(var(--scale-lg) * .8);
  --scale-lg-0_9x: calc(var(--scale-lg) * .9);
  --scale-lg-1x: calc(var(--scale-lg) * 1);
  --scale-lg-1_25x: calc(var(--scale-lg) * 1.25);
  --scale-lg-1_5x: calc(var(--scale-lg) * 1.5);
  --scale-lg-1_75x: calc(var(--scale-lg) * 1.75);
  --scale-lg-2x: calc(var(--scale-lg) * 2);
  --scale-lg-3x: calc(var(--scale-lg) * 3);
  --scale-lg-4x: calc(var(--scale-lg) * 4);
  --scale-lg-5x: calc(var(--scale-lg) * 5);
  --scale-lg-6x: calc(var(--scale-lg) * 6);
  --scale-lg-7x: calc(var(--scale-lg) * 7);
  --scale-lg-8x: calc(var(--scale-lg) * 8);
  --scale-lg-9x: calc(var(--scale-lg) * 9);
  --scale-lg-10x: calc(var(--scale-lg) * 10);
  --scale-sl-0x: calc(var(--scale-sl) * 0);
  --scale-sl-0_1x: calc(var(--scale-sl) * .1);
  --scale-sl-0_2x: calc(var(--scale-sl) * .2);
  --scale-sl-0_25x: calc(var(--scale-sl) * .25);
  --scale-sl-0_3x: calc(var(--scale-sl) * .3);
  --scale-sl-0_4x: calc(var(--scale-sl) * .4);
  --scale-sl-0_5x: calc(var(--scale-sl) * .5);
  --scale-sl-0_6x: calc(var(--scale-sl) * .6);
  --scale-sl-0_7x: calc(var(--scale-sl) * .7);
  --scale-sl-0_75x: calc(var(--scale-sl) * .75);
  --scale-sl-0_8x: calc(var(--scale-sl) * .8);
  --scale-sl-0_9x: calc(var(--scale-sl) * .9);
  --scale-sl-1x: calc(var(--scale-sl) * 1);
  --scale-sl-1_25x: calc(var(--scale-sl) * 1.25);
  --scale-sl-1_5x: calc(var(--scale-sl) * 1.5);
  --scale-sl-1_75x: calc(var(--scale-sl) * 1.75);
  --scale-sl-2x: calc(var(--scale-sl) * 2);
  --scale-sl-3x: calc(var(--scale-sl) * 3);
  --scale-sl-4x: calc(var(--scale-sl) * 4);
  --scale-sl-5x: calc(var(--scale-sl) * 5);
  --scale-sl-6x: calc(var(--scale-sl) * 6);
  --scale-sl-7x: calc(var(--scale-sl) * 7);
  --scale-sl-8x: calc(var(--scale-sl) * 8);
  --scale-sl-9x: calc(var(--scale-sl) * 9);
  --scale-sl-10x: calc(var(--scale-sl) * 10);
  --scale-xl-0x: calc(var(--scale-xl) * 0);
  --scale-xl-0_1x: calc(var(--scale-xl) * .1);
  --scale-xl-0_2x: calc(var(--scale-xl) * .2);
  --scale-xl-0_25x: calc(var(--scale-xl) * .25);
  --scale-xl-0_3x: calc(var(--scale-xl) * .3);
  --scale-xl-0_4x: calc(var(--scale-xl) * .4);
  --scale-xl-0_5x: calc(var(--scale-xl) * .5);
  --scale-xl-0_6x: calc(var(--scale-xl) * .6);
  --scale-xl-0_7x: calc(var(--scale-xl) * .7);
  --scale-xl-0_75x: calc(var(--scale-xl) * .75);
  --scale-xl-0_8x: calc(var(--scale-xl) * .8);
  --scale-xl-0_9x: calc(var(--scale-xl) * .9);
  --scale-xl-1x: calc(var(--scale-xl) * 1);
  --scale-xl-1_25x: calc(var(--scale-xl) * 1.25);
  --scale-xl-1_5x: calc(var(--scale-xl) * 1.5);
  --scale-xl-1_75x: calc(var(--scale-xl) * 1.75);
  --scale-xl-2x: calc(var(--scale-xl) * 2);
  --scale-xl-3x: calc(var(--scale-xl) * 3);
  --scale-xl-4x: calc(var(--scale-xl) * 4);
  --scale-xl-5x: calc(var(--scale-xl) * 5);
  --scale-xl-6x: calc(var(--scale-xl) * 6);
  --scale-xl-7x: calc(var(--scale-xl) * 7);
  --scale-xl-8x: calc(var(--scale-xl) * 8);
  --scale-xl-9x: calc(var(--scale-xl) * 9);
  --scale-xl-10x: calc(var(--scale-xl) * 10);
  --scale-xxl-0x: calc(var(--scale-xxl) * 0);
  --scale-xxl-0_1x: calc(var(--scale-xxl) * .1);
  --scale-xxl-0_2x: calc(var(--scale-xxl) * .2);
  --scale-xxl-0_25x: calc(var(--scale-xxl) * .25);
  --scale-xxl-0_3x: calc(var(--scale-xxl) * .3);
  --scale-xxl-0_4x: calc(var(--scale-xxl) * .4);
  --scale-xxl-0_5x: calc(var(--scale-xxl) * .5);
  --scale-xxl-0_6x: calc(var(--scale-xxl) * .6);
  --scale-xxl-0_7x: calc(var(--scale-xxl) * .7);
  --scale-xxl-0_75x: calc(var(--scale-xxl) * .75);
  --scale-xxl-0_8x: calc(var(--scale-xxl) * .8);
  --scale-xxl-0_9x: calc(var(--scale-xxl) * .9);
  --scale-xxl-1x: calc(var(--scale-xxl) * 1);
  --scale-xxl-1_25x: calc(var(--scale-xxl) * 1.25);
  --scale-xxl-1_5x: calc(var(--scale-xxl) * 1.5);
  --scale-xxl-1_75x: calc(var(--scale-xxl) * 1.75);
  --scale-xxl-2x: calc(var(--scale-xxl) * 2);
  --scale-xxl-3x: calc(var(--scale-xxl) * 3);
  --scale-xxl-4x: calc(var(--scale-xxl) * 4);
  --scale-xxl-5x: calc(var(--scale-xxl) * 5);
  --scale-xxl-6x: calc(var(--scale-xxl) * 6);
  --scale-xxl-7x: calc(var(--scale-xxl) * 7);
  --scale-xxl-8x: calc(var(--scale-xxl) * 8);
  --scale-xxl-9x: calc(var(--scale-xxl) * 9);
  --scale-xxl-10x: calc(var(--scale-xxl) * 10);
  --scale-xxxl-0x: calc(var(--scale-xxxl) * 0);
  --scale-xxxl-0_1x: calc(var(--scale-xxxl) * .1);
  --scale-xxxl-0_2x: calc(var(--scale-xxxl) * .2);
  --scale-xxxl-0_25x: calc(var(--scale-xxxl) * .25);
  --scale-xxxl-0_3x: calc(var(--scale-xxxl) * .3);
  --scale-xxxl-0_4x: calc(var(--scale-xxxl) * .4);
  --scale-xxxl-0_5x: calc(var(--scale-xxxl) * .5);
  --scale-xxxl-0_6x: calc(var(--scale-xxxl) * .6);
  --scale-xxxl-0_7x: calc(var(--scale-xxxl) * .7);
  --scale-xxxl-0_75x: calc(var(--scale-xxxl) * .75);
  --scale-xxxl-0_8x: calc(var(--scale-xxxl) * .8);
  --scale-xxxl-0_9x: calc(var(--scale-xxxl) * .9);
  --scale-xxxl-1x: calc(var(--scale-xxxl) * 1);
  --scale-xxxl-1_25x: calc(var(--scale-xxxl) * 1.25);
  --scale-xxxl-1_5x: calc(var(--scale-xxxl) * 1.5);
  --scale-xxxl-1_75x: calc(var(--scale-xxxl) * 1.75);
  --scale-xxxl-2x: calc(var(--scale-xxxl) * 2);
  --scale-xxxl-3x: calc(var(--scale-xxxl) * 3);
  --scale-xxxl-4x: calc(var(--scale-xxxl) * 4);
  --scale-xxxl-5x: calc(var(--scale-xxxl) * 5);
  --scale-xxxl-6x: calc(var(--scale-xxxl) * 6);
  --scale-xxxl-7x: calc(var(--scale-xxxl) * 7);
  --scale-xxxl-8x: calc(var(--scale-xxxl) * 8);
  --scale-xxxl-9x: calc(var(--scale-xxxl) * 9);
  --scale-xxxl-10x: calc(var(--scale-xxxl) * 10);
  --size-text-0x: calc(var(--size-text) * 0);
  --size-text-0_1x: calc(var(--size-text) * .1);
  --size-text-0_2x: calc(var(--size-text) * .2);
  --size-text-0_25x: calc(var(--size-text) * .25);
  --size-text-0_3x: calc(var(--size-text) * .3);
  --size-text-0_4x: calc(var(--size-text) * .4);
  --size-text-0_5x: calc(var(--size-text) * .5);
  --size-text-0_6x: calc(var(--size-text) * .6);
  --size-text-0_7x: calc(var(--size-text) * .7);
  --size-text-0_75x: calc(var(--size-text) * .75);
  --size-text-0_8x: calc(var(--size-text) * .8);
  --size-text-0_9x: calc(var(--size-text) * .9);
  --size-text-1x: calc(var(--size-text) * 1);
  --size-text-1_25x: calc(var(--size-text) * 1.25);
  --size-text-1_5x: calc(var(--size-text) * 1.5);
  --size-text-1_75x: calc(var(--size-text) * 1.75);
  --size-text-2x: calc(var(--size-text) * 2);
  --size-text-3x: calc(var(--size-text) * 3);
  --size-text-4x: calc(var(--size-text) * 4);
  --size-text-5x: calc(var(--size-text) * 5);
  --size-text-6x: calc(var(--size-text) * 6);
  --size-text-7x: calc(var(--size-text) * 7);
  --size-text-8x: calc(var(--size-text) * 8);
  --size-text-9x: calc(var(--size-text) * 9);
  --size-text-10x: calc(var(--size-text) * 10);
  --size-space-0x: calc(var(--size-space) * 0);
  --size-space-0_1x: calc(var(--size-space) * .1);
  --size-space-0_2x: calc(var(--size-space) * .2);
  --size-space-0_25x: calc(var(--size-space) * .25);
  --size-space-0_3x: calc(var(--size-space) * .3);
  --size-space-0_4x: calc(var(--size-space) * .4);
  --size-space-0_5x: calc(var(--size-space) * .5);
  --size-space-0_6x: calc(var(--size-space) * .6);
  --size-space-0_7x: calc(var(--size-space) * .7);
  --size-space-0_75x: calc(var(--size-space) * .75);
  --size-space-0_8x: calc(var(--size-space) * .8);
  --size-space-0_9x: calc(var(--size-space) * .9);
  --size-space-1x: calc(var(--size-space) * 1);
  --size-space-1_25x: calc(var(--size-space) * 1.25);
  --size-space-1_5x: calc(var(--size-space) * 1.5);
  --size-space-1_75x: calc(var(--size-space) * 1.75);
  --size-space-2x: calc(var(--size-space) * 2);
  --size-space-3x: calc(var(--size-space) * 3);
  --size-space-4x: calc(var(--size-space) * 4);
  --size-space-5x: calc(var(--size-space) * 5);
  --size-space-6x: calc(var(--size-space) * 6);
  --size-space-7x: calc(var(--size-space) * 7);
  --size-space-8x: calc(var(--size-space) * 8);
  --size-space-9x: calc(var(--size-space) * 9);
  --size-space-10x: calc(var(--size-space) * 10);
  --size-outline-zero: calc(var(--size-outline) * 0);
  --size-outline-tiny: calc(var(--size-outline) * .25);
  --size-outline-small: calc(var(--size-outline) * .5);
  --size-outline-base: calc(var(--size-outline) * 1);
  --size-outline-big: calc(var(--size-outline) * 2);
  --size-outline-huge: calc(var(--size-outline) * 2.5);
  --size-outline-massive: calc(var(--size-outline) * 3);
  --size-border-zero: calc(var(--size-border) * 0);
  --size-border-tiny: calc(var(--size-border) * .25);
  --size-border-small: calc(var(--size-border) * .5);
  --size-border-base: calc(var(--size-border) * 1);
  --size-border-big: calc(var(--size-border) * 2);
  --size-border-huge: calc(var(--size-border) * 2.5);
  --size-border-massive: calc(var(--size-border) * 3);
  --size-radius-zero: calc(var(--size-radius) * 0);
  --size-radius-tiny: calc(var(--size-radius) * .25);
  --size-radius-small: calc(var(--size-radius) * .5);
  --size-radius-base: calc(var(--size-radius) * 1);
  --size-radius-big: calc(var(--size-radius) * 2);
  --size-radius-huge: calc(var(--size-radius) * 2.5);
  --size-radius-massive: calc(var(--size-radius) * 3);
  --size-radius-quarter: 25%;
  --size-radius-half: 50%;
  --size-radius-three-quarters: 75%;
  --size-radius-full: 100%;
  --size-radius-max: 100vmax;
  --shadow-none: none;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  font-family: var(--font-body);
  font-size: var(--size-text);
  background: var(--color-background);
  color: var(--color-foreground);
  margin: 0;
}



/*# sourceMappingURL=index.02b69012.css.map */
